import { useEffect, useState } from "react";
import { fetchFinancialOverview } from "../api/Api";

export default function FinancialData({ symbol }) {
    const [financialData, setFinancialData] = useState(null);

    useEffect(() => {
        if (symbol === 'drvn') {
            setFinancialData({
                marketCap: '$2.32B',
                revenue: '$2.32B',
                netIncome: '-$761.68M',
                sharesOut: '164.08M',
                eps: '-4.77',
                peRatio: 'n/a',
                dividend: 'n/a',
                exDividendDate: 'n/a',
                volume: '442,538',
                open: '$14.02',
                previousClose: '$13.71',
                daysRange: '$13.35 - $14.02',
                fiftyTwoWeekRange: '$10.59 - $16.00',
                beta: '1.13',
                priceTarget: '$17.96 (+31.87%)',
                earningsDate: '2024-10-30',
            })
        } else {
            fetchFinancialOverview(symbol)
                .then((response) => { setFinancialData(response.data) })
                .catch((error) => { console.log(error) });
        }
    }, [symbol]);

    function renderFinancialData(startIndex, endIndex) {
        const data = [
            { title: 'Market Cap', label: financialData?.marketCap },
            { title: 'Revenue (ttm)', label: financialData?.revenue },
            { title: 'Net Income (ttm)', label: financialData?.netIncome },
            { title: 'Shares Out', label: financialData?.sharesOut },
            { title: 'EPS (ttm)', label: financialData?.eps },
            { title: 'PE Ratio', label: financialData?.peRatio },
            { title: 'Dividend', label: financialData?.dividend },
            { title: 'Ex-Dividend Date', label: financialData?.exDividendDate },
            { title: 'Volume', label: financialData?.volume },
            { title: 'Open', label: financialData?.open },
            { title: 'Previous Close', label: financialData?.previousClose },
            { title: 'Day\'s Range', label: financialData?.daysRange },
            { title: '52-Week Range', label: financialData?.fiftyTwoWeekRange },
            { title: 'Beta', label: financialData?.beta },
            { title: 'Price Target', label: financialData?.priceTarget },
            { title: 'Earnings Date', label: financialData?.earningsDate },
        ];

        return data.slice(startIndex, endIndex).map((data, index) => (
            <div key={index} className="flex justify-between mb-4">
                <span className="font-bold text-sk-lighter-grey text-sm">{data.title}</span>
                <span className="font-bold text-sk-lightest-grey text-sm">{data.label}</span>
            </div>
        ));
    }

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4 text-sk-lightest-grey">Financial Overview</h1>
            <div className="flex flex-wrap justify-center items-start">
                <div className="w-full md:w-1/2 lg:w-full xl:w-1/2 xl:border-r xl:pr-4">
                    {renderFinancialData(0, 8)}
                </div>
                <div className="w-full md:w-1/2 lg:w-full xl:w-1/2 xl:pl-4">
                    {renderFinancialData(8, 16)}
                </div>
            </div>
        </div>
    );
}


