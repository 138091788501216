import axios from "axios";

const BASE_URL = "http://localhost:8080";

export function fetchStockPrice(symbol, diagramInterval) {
    return axios.get(`${BASE_URL}/rest/v1/stockprice/${symbol}?interval=${diagramInterval}`);
}

export function fetchIncomeStatement(symbol) {
    return axios.get(`${BASE_URL}/rest/v1/incomestatement/${symbol}`);
}

export function fetchIncomeStatementQuarterly(symbol) {
    return axios.get(`${BASE_URL}/rest/v1/incomestatement-quarterly/${symbol}`);
}

export function fetchFinancialOverview(symbol) {
    return axios.get(`${BASE_URL}/rest/v1/financial-overview/${symbol}`);
}

export function fetchRatios(symbol) {
    return axios.get(`${BASE_URL}/rest/v1/ratio/${symbol}`);
}

export function fetchRatiosQuarterly(symbol) {
    return axios.get(`${BASE_URL}/rest/v1/ratio-quarterly/${symbol}`);
}

export function fetchRatiosAverage(industry) {
    if (industry !== null) {
        return axios.get(`${BASE_URL}/rest/v1/ratio-avg?type=industry&industry=${industry}`);
    }
    return axios.get(`${BASE_URL}/rest/v1/ratio-avg?type=market`);
}

export function fetchRatiosQuarterlyAverage(type) {
    return axios.get(`${BASE_URL}/rest/v1/ratio-quarterly-avg?type=${type}`);
}

export function fetchStockBase() {
    return axios.get(`${BASE_URL}/rest/v1/stockbase`);
}