import { useEffect } from 'react';
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/Dashboard';
import StockDetail from './pages/StockDetail';

function App() {
  useEffect(() => {
    ReactGA.initialize("G-Z3ZJ8F31K3");
    // required for the layout (https://tailwindui.com/components/application-ui/application-shells/stacked#component-10058606cac5398d7fa2c73b64089874)
    document.documentElement.classList.add('h-full');
    document.documentElement.classList.add('bg-main-background-grey');
    document.body.classList.add('h-full');

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.documentElement.classList.remove('h-full');
      document.documentElement.classList.remove('main-background-grey');
      document.body.classList.remove('h-full');
    };
  }, []); // Empty dependency array ensures this runs once on mount and unmount

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/stock/:symbol" element={<StockDetail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
