import FinancialValues from "../components/FinancialValues";

export default function BalanceSheet({ symbol }) {
    const totalCurrentAssets = [821110000, 571410000, 768930000, 373680000, 185320000];
    const totalLongTermAssets = [5090000000, 5928000000, 5088000000, 4281000000, 1691000000];
    const yearsAssets = [2023, 2022, 2021, 2020, 2019];

    const totalLongTermLiabilities = [4577000000, 4241000000, 3734000000, 3243000000, 1428000000];
    const totalCurrentLiabilities = [427170000, 505420000, 477780000, 305450000, 158830000];
    const yearsLiabilities = [2023, 2022, 2021, 2020, 2019];

    return (
        <>
            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <FinancialValues
                    years={yearsAssets}
                    valuesFirst={totalCurrentAssets}
                    valuesSecond={totalLongTermAssets}
                    titleFirst='Total Current Assets'
                    titleSecond='Total Long-Term Assets'
                    titleTotal='Total Assets'
                    colorFirst="rgba(0, 64, 228, 0.70)"
                    colorSecond="rgba(158, 2, 217, 0.70)"
                    colorTotal="rgba(0, 163, 255, 0.60)"
                />
            </div>

            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <FinancialValues
                    years={yearsLiabilities}
                    valuesFirst={totalCurrentLiabilities}
                    valuesSecond={totalLongTermLiabilities}
                    titleFirst='Total Current Liabilities'
                    titleSecond='Total Long-Term Liabilities'
                    titleTotal='Total Liabilities'
                    colorFirst="rgba(0, 163, 255, 0.60)"
                    colorSecond="rgba(228, 31, 123, 0.60)"
                    colorTotal="rgba(0, 127, 133, 1)"
                />
            </div>
        </>
    );
}