export default function StockPriceInfo({ symbol }) {
    return (
        <>
            <div className="col-span-1">
                <div className="text-sk-lightest-grey text-lg font-bold border-b border-sk-lighter-grey pb-2">
                    Driven Brands Holdings Inc. (DRVN)
                </div>
            </div>
            <div className="col-span-1">
            </div>
            <div className="col-span-1">
                <div className="flex flex-wrap items-end">
                    <div className="text-sk-lightest-grey text-2xl font-bold mr-2">
                        $13.62
                    </div>
                    <div className="mr-2 text-sk-red text-sm">
                        -0.09 (-0.66%)
                    </div>
                    <div className="text-sk-lighter-grey text-sm">
                        At close: Sep 11, 2024, 4:00 PM EDT
                    </div>
                </div>
                {/* <div className="flex flex-wrap items-end mt-2">
                    <div className="text-sm text-sk-lightest-grey mr-2">
                        $12.75
                    </div>
                    <div className="mr-2 text-sk-red text-sm">
                        -0.29 (-2.22%)
                    </div>
                    <div className="text-sm text-sk-lighter-grey">
                        At After-hours: Jul 24, 2024, 4:00 PM EDT
                    </div>
                </div> */}
                <div className="text-sk-lighter-grey text-md mt-4">
                    Management Summary:
                    <ul className="list-disc list-inside">
                        <li>The company has experienced a significant decline in its stock value over the past 12 months, with its price dropping from a high of $27.65 to around $13.62 as of recent trading.</li>
                        <li>According to 12 analysts, the average rating for DRVN stock is "Buy." The 12-month stock price forecast is $17.96, which is an increase of 31.87% from the latest price.</li>
                        <li>Negative result in 2024 because of a multi million goodwill impairment</li>
                    </ul>
                    <br />
                    Driven Brands Holdings Inc., together with its subsidiaries, provides automotive services to retail and commercial customers in the United States, Canada, and internationally.
                    <br />
                    <br />
                    It offers various services, such as paint, collision, glass, repair, car wash, oil change, and maintenance services.
                    <br />
                    <br />
                    The company also distributes automotive parts, including radiators, air conditioning components, and exhaust products to automotive repair shops, auto parts stores, body shops, and other auto repair outlets; windshields and glass accessories through a network of distribution centers; and consumable products, such as oil filters and wiper blades, as well as training services to repair and maintenance, and paint and collision shops.
                    <br />
                    <br />
                    It sells its products and services under the CARSTAR, IMO, MAACO, Meineke Car Care Centers, PH Vitres D’Autos, Take 5 Oil Change, Take 5 Car Wash, Auto Glass Now, Fix Auto USA, and 1-800-Radiator & A/C, Spire Supply, and Automotive Training Institute brands.
                    <br />
                    <br />
                    The company was founded in 1972 and is headquartered in Charlotte, North Carolina.
                </div>
            </div>
            <div className="col-span-1 divide-y divide-gray-200 text-sk-lighter-grey text-md">
                Driven Brands Holdings Inc. (DRVN) has experienced a significant decline in its stock value over the past 12 months, with its price dropping from a high of $27.65 to around $13.62 as of recent trading. Several factors have contributed to this decrease:
                <br />
                <br />
                Financial Performance: Driven Brands' earnings have fallen short of expectations in recent quarters. For example, their first quarter 2024 earnings missed analyst expectations, which negatively impacted investor confidence.
                <br />
                <br />
                Analyst Downgrades: The stock has seen several analyst downgrades and target price reductions over the past year. Major firms like JPMorgan, Barclays, and Morgan Stanley have lowered their price targets and ratings for DRVN, citing concerns over financial performance and market conditions.
                <br />
                <br />
                Management Changes: There have been significant changes in the company's executive team, including the transition of the Chief Financial Officer (CFO). Such changes often bring uncertainty and can impact stock performance negatively.
                <br />
                <br />
                Market Sentiment: Overall investor sentiment towards Driven Brands has been mixed, with a noticeable portion of the stock being sold short. While there is a consensus rating of "Moderate Buy," the short interest indicates some level of bearish sentiment among investors.
                <br />
                <br />
                Class Action Lawsuits: The company has faced legal challenges, including class action lawsuits, which can add to the uncertainty and negatively affect stock prices.
            </div>
        </>
    );
}