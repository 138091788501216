import { useEffect, useState } from "react";
import SingleFinancialValueNew from "./SingleFinancialValueNew";

export default function CashFlow({ symbol }) {
    const [years, setYears] = useState([]);
    const [investingCashFlow, setInvestingCashFlow] = useState([]);
    const [financingCashFlow, setFinancingCashFlow] = useState([]);

    const [quarters, setQuarters] = useState([]);
    const [investingCashFlowQuarterly, setInvestingCashFlowQuarterly] = useState([]);
    const [financingCashFlowQuarterly, setFinancingCashFlowQuarterly] = useState([]);

    useEffect(() => {
        if (symbol === 'drvn') {
            setYears([2023, 2022, 2021, 2020, 2019]);
            setInvestingCashFlow([-451410000, -840280000, -814940000, -57320000, -482420000]);
            setFinancingCashFlow([170700000, 343370000, 885540000, 118640000, 446530000]);
            setQuarters(['2024-03-30', '2023-12-30', '2023-09-30', '2023-07-01', '2023-04-01']);
            setInvestingCashFlowQuarterly([250000, -34280000, -90200000, -140110000, -39410000, -181690000]);
            setFinancingCashFlowQuarterly([-68840000, -33220000, 22850000, 52720000, -13360000, 108490000]);
        }
    }, [symbol]);

    return (
        <>
            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <SingleFinancialValueNew
                    years={years}
                    quarters={quarters}
                    values={investingCashFlow}
                    valuesQuarterly={investingCashFlowQuarterly}
                    title='Investing Cash Flow'
                    color='rgba(0, 127, 133, 1)'
                />
            </div>

            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <SingleFinancialValueNew
                    years={years}
                    quarters={quarters}
                    values={financingCashFlow}
                    valuesQuarterly={financingCashFlowQuarterly}
                    title='Financing Cash Flow'
                    color='rgba(0, 127, 133, 1)'
                />
            </div>
        </>
    )
}