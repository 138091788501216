import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from "react";

export default function ColumnSelectionModal(props) {
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (props.open) {
            setColumns([...props.columns]); // Reset the columns state to the initial state
        }
    }, [props.open, props.columns]);

    const closeModalHandler = () => {
        props.setOpen(false);
    }

    const handleColumnClick = (clickedColumn) => {
        setColumns(columns.map(column =>
            column.key === clickedColumn.key
                ? { ...column, display: !column.display }
                : column
        ));
    };

    const isColumnActive = (key) => {
        const column = columns.find(column => column.key === key);
        return column ? column.display : false;
    }

    const updateActiveColumns = () => {
        props.setActiveColumns(columns);
    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {
                    closeModalHandler();
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-sk-darker-grey px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="text-center">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-sk-lightest-grey">
                                            Select columns
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-sk-lighter-grey">
                                                Choose the columns for the table view. If you select too many columns to fit the screen, the display will be adjusted accordingly.
                                            </p>
                                        </div>
                                        <div className="mt-4">
                                            <div className="grid grid-cols-3 gap-1">
                                                {columns.map((column, index) => (
                                                    <button
                                                        key={index}
                                                        className={`h-16 px-3 py-2 rounded-md text-sm font-medium ${isColumnActive(column.key) ? 'bg-sk-azure text-sk-lightest-grey' : 'bg-sk-lighter-grey text-black'
                                                            }`}
                                                        onClick={() => {
                                                            handleColumnClick(column);
                                                        }}
                                                    >
                                                        {column.fullTitle}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 flex">
                                    <button
                                        type="button"
                                        className="mr-2 inline-flex w-full justify-center rounded-md bg-sk-red px-3 py-2 text-sm font-semibold text-sk-lightest-grey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={() => {
                                            closeModalHandler();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-2 inline-flex w-full justify-center rounded-md bg-sk-azure px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={() => {
                                            updateActiveColumns();
                                            closeModalHandler();
                                        }}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}