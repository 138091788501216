import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { classNames } from '../misc/Utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

// first example with revenues and revenues growth
export default function SingleFinancialValueNew(props) {
    const containerRef = useRef();
    const [currentTab, setCurrentTab] = useState('Year');
    const [containerWidth, setContainerWidth] = useState(0);
    const [isLineVisible, setIsLineVisible] = useState(false);

    // the data used to display this component is preset with the yearly data
    // if the user clicks on quart, the quarterly data is used instead
    const [data, setData] = useState(props.values);
    const [dates, setDates] = useState(props.years);

    useEffect(() => {
        if (currentTab === 'Year') {
            setData(props.values);
            setDates(props.years);
        } else {
            setData(props.valuesQuarterly);
            setDates(props.quarters);
        }
    }, [currentTab, props.values, props.valuesQuarterly, props.years, props.quarters]);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.scrollWidth);
            }
        };

        updateWidth(); // Update width on component mount
        window.addEventListener('resize', updateWidth); // Update width on window resize

        // Cleanup function to remove the event listener when the component unmounts
        return () => window.removeEventListener('resize', updateWidth);
    }, [data, dates]);

    const dataLine = {
        labels: dates,
        datasets: [
            {
                label: props.title,
                data: data,
                fill: false,
                backgroundColor: props.color,
                borderColor: props.color,
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                display: true,
                grid: {
                    display: true,
                    color: 'rgba(231, 234, 239, 0.1)',
                },
                ticks: {
                    display: true
                },
            },
            y: {
                display: true,
                grid: {
                    display: true,
                    color: 'rgba(231, 234, 239, 0.1)',
                },
                ticks: {
                    display: false
                },
            }
        }
    }

    const tabs = [
        { name: 'Year', href: '#', current: true },
        { name: 'Quart', href: '#', current: false },
    ]

    function formatMonetaryValue(value) {
        if (value % 1000000 === 0) {
            return (value / 1000000).toLocaleString() + 'M';
        } else if (value % 1000 === 0) {
            return (value / 1000).toLocaleString() + 'T';
        } else {
            return value.toLocaleString();
        }
    }

    return (
        <div>
            <div>
                <div className="flex justify-between mb-4 items-center">
                    <span className="text-sk-lightest-grey text-sm font-bold">{props.title}</span>
                    <button onClick={() => setIsLineVisible(!isLineVisible)}>
                        <ChartBarSquareIcon className={classNames(
                            isLineVisible
                                ? 'text-sk-lightest-grey'
                                : 'text-sk-lighter-grey',
                            'w-7 h-7'
                        )} />
                    </button>
                    <nav className="-mb-px flex space-x-8 justify-center">
                        {tabs.map((tab) => (
                            <a
                                key={tab.name}
                                href={tab.href}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentTab(tab.name);
                                }}
                                className={classNames(
                                    tab.name === currentTab
                                        ? 'border-sk-azure text-sk-lightest-grey'
                                        : 'border-transparent text-sk-lighter-grey hover:border-sk-lighter-grey',
                                    'whitespace-nowrap border-b-2 px-1 pb-1 font-medium text-sm'
                                )}
                                aria-current={tab.name === currentTab ? 'page' : undefined}
                            >
                                {tab.name}
                            </a>
                        ))}
                    </nav>
                </div>
                <div className="overflow-x-auto">
                    <div className="overflow-x-auto pb-2">
                        <div ref={containerRef} className="grid grid-flow-row auto-rows-min gap-4">
                            <div className="grid grid-flow-col gap-4" style={{ gridTemplateColumns: `repeat(${dates.length}, minmax(120px, 1fr))` }}>
                                {dates.map((date, index) => {
                                    return (
                                        <div key={index} className="pr-2 font-bold text-sm text-sk-lighter-grey whitespace-nowrap">
                                            {currentTab === 'Quart' ? format(new Date(date), 'MMM yyyy') : date}
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="grid grid-flow-col gap-4" style={{ gridTemplateColumns: `repeat(${data.length}, minmax(120px, 1fr))` }}>
                                {data.map((value, index) => {
                                    const nextValue = index < data.length - 1 ? data[index + 1] : value;

                                    let percentageChange;
                                    percentageChange = (value - nextValue) / Math.abs(nextValue);
                                    percentageChange *= 100;
                                    
                                    const isInvalidPercentage = percentageChange === null || isNaN(percentageChange) || !isFinite(percentageChange);
                                    const displayPercentage = isInvalidPercentage ? '-%' : `${percentageChange.toFixed(1)}%`;

                                    return (
                                        <div key={index} className="pr-2 text-sm text-sk-lightest-grey whitespace-nowrap">
                                            {formatMonetaryValue(value)}
                                            <span
                                                className={classNames(
                                                    'ml-1 text-xs',
                                                    !isInvalidPercentage && percentageChange > 0 ? 'text-green-500' :
                                                    !isInvalidPercentage && percentageChange < 0 ? 'text-red-500' :
                                                    'text-gray-500'
                                                )}
                                            >
                                                {displayPercentage}
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {isLineVisible && (
                            <div style={{ width: `${containerWidth}px` }} className="w-full h-[240px] lg:pl-8 lg:pr-8 md:pl-6 md:pr-6 sm:pl-4 sm:pr-4 mt-4">
                                <Line key={containerWidth} data={dataLine} options={options} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}