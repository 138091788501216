import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

// For now the props conain financial data for two values
// e.g. first value and second value - the total will be calculated
// from these two values. Also, the years are passed as props.
// The titles of the first, second and total values are also passed as props.
export default function FinancialValues(props) {
    const containerRef = useRef();
    const [containerWidth, setContainerWidth] = useState(0);

    const valuesTotal = props.valuesFirst.map((value, index) => value + props.valuesSecond[index]);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(Math.max(containerRef.current.offsetWidth, 80 * props.years.length));
            }
        };

        updateWidth(); // Update width on component mount
        window.addEventListener('resize', updateWidth); // Update width on window resize

        // Cleanup function to remove the event listener when the component unmounts
        return () => window.removeEventListener('resize', updateWidth);
    }, [props.years.length]);

    const data = {
        labels: props.years,
        datasets: [
            {
                label: props.titleFirst,
                data: props.valuesFirst,
                fill: false,
                backgroundColor: props.colorFirst,
                borderColor: props.colorFirst,
            },
            {
                label: props.titleSecond,
                data: props.valuesSecond,
                fill: false,
                backgroundColor: props.colorSecond,
                borderColor: props.colorSecond,
            },
            {
                label: props.titleTotal,
                data: valuesTotal,
                fill: false,
                backgroundColor: props.colorTotal,
                borderColor: props.colorTotal,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                },
                ticks: {
                    display: false
                },
            },
            y: {
                display: false,
                ticks: {
                    display: false
                },
            }
        }
    }

    const barChartData = (index) => {
        const dataAtIndexFirst = props.valuesFirst[index];
        const dataAtIndexSecond = props.valuesSecond[index];

        return {
            labels: [''],
            datasets: [
                {
                    label: props.titleFirst,
                    data: [dataAtIndexFirst],
                    fill: false,
                    backgroundColor: props.colorFirst,
                    borderColor: props.colorFirst,
                },
                {
                    label: props.titleSecond,
                    data: [dataAtIndexSecond],
                    fill: false,
                    backgroundColor: props.colorSecond,
                    borderColor: props.colorSecond,
                },
            ],
        };
    }

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        events: [],
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                display: false,
                stacked: true,
                ticks: {
                    display: false
                },
            },
            y: {
                display: false,
                stacked: true,
                ticks: {
                    display: false
                },
                max: Math.max(...valuesTotal)
            },
        },
    }

    function formatMonetaryValue(value) {
        if (value % 1000000 === 0) {
            return (value / 1000000).toLocaleString() + 'M';
        } else if (value % 1000 === 0) {
            return (value / 1000).toLocaleString() + 'T';
        } else {
            return value.toLocaleString();
        }
    }

    return (
        <div>
            <div className="flex">
                <div className="border-r border-main-background-grey pr-4">
                    <div title={props.titleTotal}
                        className="flex items-center font-bold mb-2 text-sm text-sk-lighter-grey mt-[37px] h-[20px]">
                        <div className="w-4 h-4 mr-2" style={{ backgroundColor: props.colorTotal }}></div>
                        <span className="hidden sm:flex whitespace-nowrap">{props.titleTotal}</span>
                    </div>
                    <div title={props.titleFirst}
                        className="flex items-center font-bold mb-2 text-sm text-sk-lighter-grey mt-[152px] h-[20px]">
                        <div className="w-4 h-4 mr-2" style={{ backgroundColor: props.colorFirst }}></div>
                        <span className="hidden sm:flex whitespace-nowrap">{props.titleFirst}</span>
                    </div>
                    <div title={props.titleSecond}
                        className="flex items-center font-bold text-sm text-sk-lighter-grey h-[20px]">
                        <div className="w-4 h-4 mr-2" style={{ backgroundColor: props.colorSecond }}></div>
                        <span className="hidden sm:flex whitespace-nowrap">{props.titleSecond}</span>
                    </div>
                </div>
                <div className="overflow-x-auto ml-4 flex-grow">
                    <div ref={containerRef} className="flex flex-col">
                        <div className="flex justify-between h-[20px]">
                            {props.years.map((year, index) => (
                                <div key={index} className="min-w-[80px] flex-shrink-0 font-bold text-sm text-sk-lighter-grey">{year}</div>
                            ))}
                        </div>
                        <div style={{ width: `${containerWidth}px` }} className="border-b mb-4"></div>
                        <div className="flex justify-between mb-2 h-[20px]">
                            {valuesTotal.map((value, index) => (
                                <div key={index} className="min-w-[80px] flex-shrink-0 text-sm text-sk-lightest-grey">{formatMonetaryValue(value)}</div>
                            ))}
                        </div>

                        <div className="flex justify-between mb-4 h-[120px]">
                            {props.valuesFirst.map((value, index) => (
                                <div key={index} className="w-[80px] flex-shrink-0 pr-6 pt-4">
                                    <Bar data={barChartData(index)} options={barChartOptions} />
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-between mt-2 mb-2 h-[20px]">
                            {props.valuesFirst.map((value, index) => (
                                <div key={index} className="min-w-[80px] flex-shrink-0 text-sm text-sk-lightest-grey">{formatMonetaryValue(value)}</div>
                            ))}
                        </div>
                        <div className="flex justify-between mb-2 h-[20px]">
                            {props.valuesSecond.map((value, index) => (
                                <div key={index} className="min-w-[80px] flex-shrink-0 text-sm text-sk-lightest-grey">{formatMonetaryValue(value)}</div>
                            ))}
                        </div>

                        <div style={{ width: `${containerWidth}px` }} className="w-full h-[240px] lg:pl-8 lg:pr-8 md:pl-6 md:pr-6 sm:pl-4 sm:pr-4">
                            <Line key={containerWidth} data={data} options={options} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="sm:hidden flex-col items-start mt-4">
                <div className="flex items-center font-bold mb-2 text-sm text-sk-lighter-grey">
                    <div className="w-4 h-4 mr-2" style={{ backgroundColor: props.colorTotal }}></div>
                    {props.titleTotal}
                </div>
                <div className="flex items-center font-bold mb-2 text-sm text-sk-lighter-grey">
                    <div className="w-4 h-4 mr-2" style={{ backgroundColor: props.colorFirst }}></div>
                    {props.titleFirst}
                </div>
                <div className="flex items-center font-bold text-sm text-sk-lighter-grey">
                    <div className="w-4 h-4 mr-2" style={{ backgroundColor: props.colorSecond }}></div>
                    {props.titleSecond}
                </div>
            </div>
        </div>
    );
}
