import NavBar from '../components/NavBar';
import StockTable from '../components/StockTable';

export default function Dashboard() {
    return (
        <div className="min-h-full">
            <NavBar />

            <header className="shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-sk-lighter-grey">Dashboard</h1>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                    <StockTable />
                </div>
            </main>
        </div>
    );
}