import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BalanceSheet from "../components/BalanceSheet";
import CashFlow from "../components/CashFlow";
import FinancialData from "../components/FinancialData";
import Income from "../components/Income";
import NavBar from '../components/NavBar';
import RatiosNew from "../components/RatiosNew";
import StockPriceDiagram from "../components/StockPrice";
import StockPriceInfo from "../components/StockPriceInfo";
import { classNames } from "../misc/Utils";

export default function StockDetail() {
    let { symbol } = useParams();
    const { state } = useLocation();
    // const { industry } = state;
    const [currentTab, setCurrentTab] = useState('Balance Sheet');

    const tabs = [
        { name: 'Income', href: '#', current: false },
        { name: 'Balance Sheet', href: '#', current: true },
        { name: 'Cash Flow', href: '#', current: false },
        { name: 'KPIs / Ratios', href: '#', current: false },
    ]

    return (
        <div className="min-h-full">
            <NavBar />

            <main>
                <div className="mx-auto max-w-7xl py-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-y-2 gap-x-6 lg:grid-cols-2">
                        <StockPriceInfo symbol={symbol} />
                    </div>

                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 mt-4">
                        <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-sk-darker-grey shadow p-4">
                            <StockPriceDiagram symbol={symbol} />
                        </div>
                        <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-sk-darker-grey shadow p-4">
                            <FinancialData symbol={symbol} />
                        </div>
                    </div>

                    <div className="text-sk-lighter-grey text-md mt-4 sm:mt-4">
                        The PE ratio average of the sector consumer discretionary is 26.72 and the PE ratio of the industry auto & truck dealerships is 19.75.
                        <br />
                        <br />
                        The current Price-to-Earnings (P/E) ratio for Driven Brands Holdings Inc. (DRVN) cannot be determined due to the company’s negative 
                        earnings over the past 12 months. Specifically, Driven Brands has reported a loss per share of -$4.77, making the traditional P/E ratio calculation inapplicable at this time.
                        <br />
                        <br />
                        For future valuations, analysts use the forward P/E ratio, which for DRVN is currently projected at 12.77. This suggests that analysts expect the company to return to profitability in the next fiscal year.
                        The forward P/E ratio is calculated using the forecasted EPS - earnings per share. The next quarter should be published in the beginning of October - a first indication might already be visible then.
                        In this case, the P/E ratio is considerably lower than the average of it’s industry and sector.
                        <br />
                        <br />
                        The first quarter was published on May 2nd, 2024. DRVN reported $0.18 earnings per share for the quarter, hitting the consensus estimate of $0.18. The company had revenue of $572.23 million for the quarter, compared to the consensus estimate of $584.31 million. Driven Brands has generated ($4.66) earnings per share over the last year (($4.66) diluted earnings per share).
                        <br />
                        <br />
                        Earnings for Driven Brands are expected to grow by 29.89% in the coming year, from $0.87 to $1.13 per share.
                        <br />
                        <br />
                        Looking at the yearly reporting, revenue grew constantly while there was a spike in operating expenses that lead to having a negative result in 2023. Taking a closer look at the quarters, there was a spike specifically in the third quarter of 2023.
                        This spike happened because of a goodwill impairment valued at 851 million USD in the Car Wash segment.
                    </div>

                    <div className="mt-4 sm:mt-4">
                        <div className="sm:hidden">
                            <label htmlFor="current-tab" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="current-tab"
                                name="current-tab"
                                className="block w-full h-10 rounded-md bg-sk-lightest-grey border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                defaultValue={tabs.find((tab) => tab.current).name}
                                onChange={(e) => {
                                    setCurrentTab(e.target.value);
                                }}
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.name}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="-mb-px flex space-x-8 justify-center">
                                {tabs.map((tab) => (
                                    <a
                                        key={tab.name}
                                        href={tab.href}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentTab(tab.name);
                                        }}
                                        className={classNames(
                                            tab.name === currentTab
                                                ? 'border-sk-azure text-sk-lightest-grey'
                                                : 'border-transparent text-sk-lighter-grey hover:border-sk-lighter-grey',
                                            'whitespace-nowrap border-b-2 px-1 pb-2 font-medium'
                                        )}
                                        aria-current={tab.name === currentTab ? 'page' : undefined}
                                    >
                                        {tab.name}
                                    </a>
                                ))}
                            </nav>
                        </div>
                    </div>

                    {currentTab === 'Balance Sheet' && <BalanceSheet symbol={symbol} />}
                    {currentTab === 'Income' && <Income symbol={symbol} />}
                    {currentTab === 'Cash Flow' && <CashFlow symbol={symbol} />}
                    {/* {currentTab === 'KPIs / Ratios' && <Ratios symbol={symbol} industry={industry} />} */}
                    {currentTab === 'KPIs / Ratios' && <RatiosNew symbol={symbol} />}

                </div>
            </main>
        </div>
    )
}
