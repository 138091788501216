import { useEffect, useState } from "react";
import SingleFinancialValueNew from "./SingleFinancialValueNew";

export default function RatiosNew({ symbol }) {
    const [years, setYears] = useState([]);
    const [peratio, setPeRatio] = useState([]);
    const [quickRatio, setQuickRatio] = useState([]);

    const [quarters, setQuarters] = useState([]);
    const [peratioQuarterly, setPeRatioQuarterly] = useState([]);
    const [quickRatioQuarterly, setQuickRatioQuarterly] = useState([]);

    useEffect(() => {
        if (symbol === 'drvn') {
            setYears([2023, 2022, 2021, 2020, 2019]);
            setPeRatio([-3.14, 105.86, 578.67, null, null]);
            setQuickRatio([0.77, 0.81, 1.34, 0.89, 0.69]);
            setQuarters(['2024-03-30', '2023-12-30', '2023-09-30', '2023-07-01', '2023-04-01']);
            setPeRatioQuarterly([-2.68, -3.36, -3.14, -2.99, 34.02, 131.84]);
            setQuickRatioQuarterly([0.97, 0.76, 0.77, 0.79, 0.80, 0.78]);
        }
    }, [symbol]);

    return (
        <>
            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <SingleFinancialValueNew
                    years={years}
                    quarters={quarters}
                    values={peratio}
                    valuesQuarterly={peratioQuarterly}
                    title='P/E Ratio'
                    color='rgba(0, 127, 133, 1)'
                />
            </div>

            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <SingleFinancialValueNew
                    years={years}
                    quarters={quarters}
                    values={quickRatio}
                    valuesQuarterly={quickRatioQuarterly}
                    title='Quick Ratio'
                    color='rgba(0, 127, 133, 1)'
                />
            </div>
        </>
    )
}