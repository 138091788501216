import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { fetchStockPrice } from '../api/Api';
import drvnPrice1d from '../misc/drvn-price-1d.json';
import drvnPrice1m from '../misc/drvn-price-1m.json';
import drvnPrice1y from '../misc/drvn-price-1y.json';
import drvnPrice5d from '../misc/drvn-price-5d.json';
import drvnPrice5y from '../misc/drvn-price-5y.json';
import drvnPriceMax from '../misc/drvn-price-max.json';
import drvnPriceYtd from '../misc/drvn-price-ytd.json';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export default function StockPriceDiagram({ symbol }) {
    const [stockData, setStockData] = useState([]);
    const [diagramInterval, setDiagramInterval] = useState('5D');

    useEffect(() => {
        if (symbol === 'drvn' && diagramInterval === '1D') {
            setStockData(drvnPrice1d);
        } else if (symbol === 'drvn' && diagramInterval === '5D') {
            setStockData(drvnPrice5d);
        } else if (symbol === 'drvn' && diagramInterval === '1M') {
            setStockData(drvnPrice1m);
        } else if (symbol === 'drvn' && diagramInterval === 'YTD') {
            setStockData(drvnPriceYtd);
        } else if (symbol === 'drvn' && diagramInterval === '1Y') {
            setStockData(drvnPrice1y);
        } else if (symbol === 'drvn' && diagramInterval === '5Y') {
            setStockData(drvnPrice5y);
        } else if (symbol === 'drvn' && diagramInterval === 'MAX') {
            setStockData(drvnPriceMax);
        }else {
            fetchStockPrice(symbol, diagramInterval)
            .then((response) => { setStockData(response.data) })
            .catch((error) => { console.log(error) });
        }
    }, [symbol, diagramInterval]);

    const possibleIntervals = ['1D', '5D', '1M', 'YTD', '1Y', '5Y', 'MAX'];

    const verticalLinePlugin = {
        id: 'verticalLinePlugin',
        beforeDatasetsDraw: (chart) => {
            if (chart.tooltip.getActiveElements().length > 0) {
                const { ctx, scales, tooltip } = chart;
                const yScale = scales.y; // Make sure the 'y' scale exists
                const x = tooltip.caretX;
                const topY = yScale ? yScale.top : 0; // Check if yScale is defined before accessing 'top'
                const bottomY = yScale ? yScale.bottom : 0; // Check if yScale is defined before accessing 'bottom'

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = 'rgba(231, 234, 239, 0.5)';
                ctx.setLineDash([5, 5]); // Make the line dotted
                ctx.stroke();
                ctx.restore();
            }
        },
    };

    const chartLabel = () => {
        switch (diagramInterval) {
            case '1D':
                return stockData.map((data) => format(new Date(data.timestamp.value$kotlinx_datetime * 1000), 'h a'))
            case '5D':
            case '1M':
            case 'YTD':
                return stockData.map((data) => format(new Date(data.timestamp.value$kotlinx_datetime * 1000), 'MMM d'))
            default:
                return stockData.map((data) => format(new Date(data.timestamp.value$kotlinx_datetime * 1000), 'MMM yyyy'))
        }
    }

    const chartTooltipHeader = (index) => {
        const currentTimestamp = stockData[index].timestamp.value$kotlinx_datetime
        switch (diagramInterval) {
            case '1D':
            case '5D':
                return format(new Date(currentTimestamp * 1000), 'MMM d, yyyy h:mm a');
            default:
                return format(new Date(currentTimestamp * 1000), 'MMM d, yyyy');
        }
    }

    const options = {
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: false, // This will remove only the vertical lines.
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 6, // Adjust to your liking
                }
            },
            y: {
                grid: {
                    display: true, // This will ensure that horizontal lines are still shown.
                    color: 'rgba(231, 234, 239, 0.1)',
                },
            },
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                displayColors: false, // Don't display the color box
                callbacks: {
                    title: function (context) {
                        return chartTooltipHeader(context[0].parsed.x);
                    },
                },
            },
            legend: {
                display: false, // This will hide the legend.
            }
        },
    };

    const chartData = () => {
        return {
            labels: chartLabel(),
            datasets: [
                {
                    label: 'Stock Price',
                    data: stockData.map((data) => data.price),
                    fill: true,
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 220);
                        gradient.addColorStop(0, 'rgba(0, 163, 255, 0.8)');
                        gradient.addColorStop(1, 'rgba(0, 163, 255, 0)');
                        return gradient;
                    },
                    borderColor: 'rgba(0, 163, 255, 0.80)',
                    tension: 0.1,
                    pointRadius: 0,
                    borderWidth: 2,
                },
            ],
        }
    };

    return (
        <div>
            <div className="flex justify-end space-x-2">
                {possibleIntervals.map((interval) => {
                    return (
                        <button
                            key={interval}
                            onClick={() => setDiagramInterval(interval)}
                            className={`px-2 py-1 text-white rounded text-sm ${diagramInterval === interval ? 'bg-sk-azure' : 'bg-sk-darker-azure hover:bg-sk-azure'}`}
                        >
                            {interval}
                        </button>
                    )
                }
                )}
            </div>
            <Line data={chartData()} options={options} plugins={[verticalLinePlugin]} />
        </div>
    );
};