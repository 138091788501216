import { useEffect, useState } from "react";
import { fetchIncomeStatement, fetchIncomeStatementQuarterly } from "../api/Api";
import SingleFinancialValueNew from "./SingleFinancialValueNew";

export default function Income({ symbol }) {
    const [years, setYears] = useState([]);
    const [revenues, setRevenues] = useState([]);
    const [grossProfit, setGrossProfit] = useState([]);
    const [netIncome, setNetIncome] = useState([]);

    const [quarters, setQuarters] = useState([]);
    const [revenuesQuarterly, setRevenuesQuarterly] = useState([]);
    const [grossProfitQuarterly, setGrossProfitQuarterly] = useState([]);
    const [netIncomeQuarterly, setNetIncomeQuarterly] = useState([]);

    useEffect(() => {
        if (symbol === 'drvn') {
            setYears([2023, 2022, 2021, 2020, 2019]);
            setRevenues([2304000000, 2033000000, 1467000000, 904200000, 600270000]);
            setGrossProfit([1032000000, 967510000, 725010000, 463860000, 323590000]);
            setNetIncome([-744960000, 43190000, 9630000, -420000, 7730000]);
            setQuarters(['2024-06-29', '2024-03-30', '2023-12-30', '2023-09-30', '2023-07-01', '2023-04-01']);
            setRevenuesQuarterly([611570000, 572230000, 553680000, 581030000, 606850000, 562470000]);
            setGrossProfitQuarterly([284880000, 264600000, 249710000, 254160000, 275750000, 252430000]);
            setNetIncomeQuarterly([30160000, 4260000, -13150000, -799310000, 37750000, 29750000]);
        } else {
            fetchIncomeStatement(symbol)
                .then((response) => {
                    setYears(response.data.map((data) => data.year));
                    setRevenues(response.data.map((data) => data.revenue));
                    setGrossProfit(response.data.map((data) => data.grossProfit));
                    setNetIncome(response.data.map((data) => data.netIncome));
                })
                .catch((error) => { console.log(error) });

            fetchIncomeStatementQuarterly(symbol)
                .then((response) => {
                    setQuarters(response.data.map((data) => data.date.value$kotlinx_datetime));
                    setRevenuesQuarterly(response.data.map((data) => data.revenue));
                    setGrossProfitQuarterly(response.data.map((data) => data.grossProfit));
                    setNetIncomeQuarterly(response.data.map((data) => data.netIncome));
                })
                .catch((error) => { console.log(error) });
        }
    }, [symbol]);

    return (
        <>
            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <SingleFinancialValueNew
                    years={years}
                    quarters={quarters}
                    values={revenues}
                    valuesQuarterly={revenuesQuarterly}
                    title='Total Revenues'
                    color='rgba(0, 127, 133, 1)'
                />
            </div>

            {/* <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <SingleFinancialValueNew
                    years={years}
                    quarters={quarters}
                    values={revenues}
                    valuesQuarterly={revenuesQuarterly}
                    title='Total Revenues'
                    color='rgba(0, 127, 133, 1)'
                />
            </div> */}

            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <SingleFinancialValueNew
                    years={years}
                    quarters={quarters}
                    values={grossProfit}
                    valuesQuarterly={grossProfitQuarterly}
                    title="Gross Profit"
                    color="rgba(228, 31, 123, 0.60)"
                />
            </div>

            <div className="rounded-lg bg-sk-darker-grey shadow p-4 mt-4">
                <SingleFinancialValueNew
                    years={years}
                    quarters={quarters}
                    values={netIncome}
                    valuesQuarterly={netIncomeQuarterly}
                    title="Net Income"
                    color="rgba(0, 163, 255, 0.60)"
                />
            </div>
        </>
    )
}