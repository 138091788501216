import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from "react";
import { classNames } from '../misc/Utils';

export default function KpiFilterModal(props) {
    const [selected, setSelected] = useState(props.kpis[1]);
    const [greaterThanValue, setGreaterThanValue] = useState('');
    const [lessThanValue, setLessThanValue] = useState('');
    const [kpiSelectionDisabled, setKpiSelectionDisabled] = useState(false);

    useEffect(() => {
        if (props.filterToUpdate) {
            setSelected(props.filterToUpdate.kpi);
            setGreaterThanValue(props.filterToUpdate.greaterThan);
            setLessThanValue(props.filterToUpdate.lessThan);
            setKpiSelectionDisabled(true);
        }
    }, [props.filterToUpdate, kpiSelectionDisabled]);

    useEffect(() => {
        if (props.kpis.length > 0) {
            setSelected(props.kpis[1]);
        }
    }, [props.kpis]);

    const closeModalHandler = () => {
        props.setOpen(false);
        setGreaterThanValue('');
        setLessThanValue('');
        setKpiSelectionDisabled(false);
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {
                    closeModalHandler();
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-sk-darker-grey px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="text-center">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-sk-lightest-grey">
                                            Add a new filter
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-sk-lighter-grey">
                                                Choose a KPI/ratio and the values you want to screen the stock market by.
                                            </p>
                                        </div>
                                        <div>
                                            <Listbox value={selected} onChange={setSelected} disabled={kpiSelectionDisabled}>
                                                {({ open }) => (
                                                    <>
                                                        <Listbox.Label className="mt-4 block text-sm font-medium leading-6 text-sk-lightest-grey text-left">KPI / Ratio</Listbox.Label>
                                                        <div className="relative mt-2">
                                                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                                <span className="block truncate">{selected.name}</span>
                                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                </span>
                                                            </Listbox.Button>

                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="absolute h-56 z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {props.kpis.map((person) => (
                                                                        <Listbox.Option
                                                                            key={person.id}
                                                                            className={({ active }) =>
                                                                                classNames(
                                                                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={person}
                                                                        >
                                                                            {({ selected, active }) => (
                                                                                <>
                                                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                        {person.name}
                                                                                    </span>

                                                                                    {selected ? (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active ? 'text-white' : 'text-indigo-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    ))}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox>
                                            <div>
                                                <label htmlFor="greater-than" className="mt-4 block text-sm font-medium leading-6 text-sk-lightest-grey text-left">
                                                    Value should be greater than
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="greater-than"
                                                        id="greater-than"
                                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="0.00"
                                                        value={greaterThanValue}
                                                        onChange={event => setGreaterThanValue(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="less-than" className="mt-4 block text-sm font-medium leading-6 text-sk-lightest-grey text-left">
                                                    Value should be less than
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="less-than"
                                                        id="less-than"
                                                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        placeholder="0.00"
                                                        value={lessThanValue}
                                                        onChange={event => setLessThanValue(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 flex">
                                    <button
                                        type="button"
                                        className="mr-2 inline-flex w-full justify-center rounded-md bg-sk-red px-3 py-2 text-sm font-semibold text-sk-lightest-grey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={() => {
                                            closeModalHandler();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-2 inline-flex w-full justify-center rounded-md bg-sk-azure px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={() => {
                                            props.addFilter(selected, greaterThanValue, lessThanValue);
                                            closeModalHandler();
                                        }}
                                    >
                                        {props.filter ? 'Update filter' : 'Add filter'}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )

}