import { PlusIcon, ViewColumnsIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { fetchStockBase } from "../api/Api";
import ColumnSelectionModal from "./ColumnSelectionModal";
import KpiFilterModal from "./KpiFilterModal";

export default function StockTable() {
    const navigate = useNavigate();

    const [kpiFilterModalOpen, setKpiFilterModalOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);
    const [filterToUpdate, setFilterToUpdate] = useState(null);
    const [columnModalOpen, setColumnModalOpen] = useState(false);
    const [data, setData] = useState([]);

    const plainFormatter = (value) => {
        if (value === null) {
            return 'n/a';
        }
        return value;
    }

    const percentageFormatter = (value) => {
        if (value === null) {
            return 'n/a';
        }
        return value + '%';
    }

    const columns = [
        {
            key: "symbol",
            name: "Symbol",
            fullTitle: "Symbol",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => plainFormatter(value),
            display: true,
        },
        {
            key: "companyName",
            name: "Company",
            fullTitle: "Company Name",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm font-medium text-sk-lightest-grey",
            valueFormatter: (value) => plainFormatter(value),
            display: false,
        },
        {
            key: "peRatio",
            name: "P/E",
            fullTitle: "P/E: Price to Earnings Ratio",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => plainFormatter(value),
            display: true,
        },
        {
            key: "psRatio",
            name: "P/S",
            fullTitle: "P/S: Price to Sales Ratio",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => plainFormatter(value),
            display: true,
        },
        {
            key: "pbRatio",
            name: "P/B",
            fullTitle: "P/B: Price to Book Ratio",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => plainFormatter(value),
            display: false,
        },
        {
            key: "debtToEquityRatio",
            name: "D/E",
            fullTitle: "D/E: Debt to Equity Ratio",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => plainFormatter(value),
            display: false,
        },
        {
            key: "dividendYield",
            name: "DY",
            fullTitle: "DY: Dividend Yield",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => plainFormatter(value),
            display: false,
        },
        {
            key: "returnOnEquity",
            name: "ROE",
            fullTitle: "ROE: Return on Equity",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => percentageFormatter(value),
            display: false,
        },
        {
            key: "returnOnAssets",
            name: "ROA",
            fullTitle: "ROA: Return on Assets",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => percentageFormatter(value),
            display: false,
        },
        {
            key: "returnOnCapital",
            name: "ROC",
            fullTitle: "ROC: Return on Capital",
            classesHeader: "",
            classesCell: "whitespace-nowrap text-sm text-sk-lightest-grey",
            valueFormatter: (value) => percentageFormatter(value),
            display: false,
        },
    ];

    const [activeColumns, setActiveColumns] = useState(columns);

    useEffect(() => {
        fetchStockBase()
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => { console.log(error) });
    }, []);

    const kpis = useMemo(() => [
        { id: 1, name: 'P/E Price to Earnings' },
        { id: 2, name: 'P/B Price to Book' },
        { id: 3, name: 'DY Dividend Yield' },
        { id: 4, name: 'ROE Return on Equity' },
        { id: 5, name: 'PEG Price Earnings to Growth' },
        { id: 6, name: 'EPS Earnings per Share' },
        { id: 7, name: 'P/S Price to Sales' },
        { id: 7, name: 'ROA Return on Assets' },
        { id: 7, name: 'ROIC Return on Capital' },
    ], []);

    const unusedKpis = useMemo(() => {
        return kpis.filter(kpi => !activeFilters.some(filter => filter.kpi.id === kpi.id));
    }, [kpis, activeFilters]);

    const handleFilterClick = (activeFilter) => {
        setFilterToUpdate(activeFilter);
        setKpiFilterModalOpen(true);
    };

    const handleAddFilterClick = () => {
        setKpiFilterModalOpen(true);
    };

    const handleAddColumnClick = () => {
        setColumnModalOpen(true);
    }

    const handleRemoveFilterClick = (event, filterToRemove) => {
        event.stopPropagation();
        setActiveFilters(activeFilters.filter(filter => filter !== filterToRemove));
    };

    const addFilter = (selectedKpi, greaterThanValue, lessThanValue) => {
        setActiveFilters(prevFilters => [...prevFilters, { kpi: selectedKpi, greaterThan: greaterThanValue, lessThan: lessThanValue }]);
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <KpiFilterModal
                kpis={unusedKpis}
                open={kpiFilterModalOpen}
                setOpen={setKpiFilterModalOpen}
                addFilter={addFilter}
                filterToUpdate={filterToUpdate}
            />

            <ColumnSelectionModal
                open={columnModalOpen}
                columns={activeColumns}
                setActiveColumns={setActiveColumns}
                setOpen={setColumnModalOpen}
            />

            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-sk-lighter-grey">Screen the stock market</h1>
                    <p className="mt-2 text-sm text-sk-lighter-grey">
                        Use the filters below as a starting point to find stocks that match your investment goals. Add filters by clicking on the plus icon.
                        Each filter can be removed by clicking on the x icon. A filter can have a greater than and a less than value. You can leave one empty
                        if you just like to use one of them.
                    </p>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 bg-sk-darker-grey rounded">
                    {/* Active filters */}
                    <div className="border-b border-gray-500">
                        <div className="mx-auto max-w-7xl px-4 py-3 sm:flex sm:items-center sm:px-6 lg:px-8">
                            <div className="flex justify-between items-start">
                                <div className="-m-1 flex flex-wrap items-center">
                                    {activeFilters.map((activeFilter) => (
                                        <button
                                            key={activeFilter.kpi.id}
                                            className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                                            onClick={() => handleFilterClick(activeFilter)}
                                        >
                                            <span>{activeFilter.kpi.name}</span>
                                            <div
                                                type="button"
                                                className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                                                onClick={(event) => handleRemoveFilterClick(event, activeFilter)}
                                            >
                                                <span className="sr-only">Remove filter for {activeFilter.kpi.name}</span>
                                                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                </svg>
                                            </div>
                                        </button>
                                    ))}
                                    {unusedKpis.length > 0 && (
                                        <button
                                            className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-sk-lightest-grey py-1.5 pl-2 pr-2 text-sm font-medium text-gray-900"
                                            onClick={() => handleAddFilterClick()}
                                        >
                                            <PlusIcon className="h-5 w-5" />
                                            <span className="ml-1 pr-1">
                                                Filter
                                            </span>
                                        </button>
                                    )}
                                </div>
                                <button
                                    className=""
                                    onClick={() => handleAddColumnClick()}
                                >
                                    <ViewColumnsIcon className="h-8 w-7 text-sk-lightest-grey" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    {activeColumns
                                        .filter(column => column.display)
                                        .map((column) => (
                                            <th
                                                key={column.key}
                                                scope="col"
                                                className="whitespace-nowrap text-left text-sm font-semibold text-sk-lighter-grey px-2 py-3 first:pl-4 last:pr-4"
                                            >
                                                {column.name}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody className="bg-sk-darker-grey">
                                {data.map((stock) => (
                                    <tr key={stock.symbol}
                                        className="even:bg-sk-darker-azure"
                                        // onClick={() => navigate(`/stock/${stock.symbol}`)}
                                        onClick={() => navigate(`/stock/${stock.symbol}`, { state: { industry: stock.industry } })}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {activeColumns
                                            .filter(column => column.display)
                                            .map((column) => (
                                                <td key={column.key} className={`first:pl-4 last:pr-4 px-2 py-3 ${column.classesCell}`}>
                                                    {column.valueFormatter(stock[column.key])}
                                                </td>
                                            ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    )
}